import 'common/styles.scss';

import { Footer, MainLayout } from '@luxe/components';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';
import CountryDetailView from 'countries/containers/CountryDetailView';
import CountryMapView from 'countries/containers/CountryMapView';
import HeaderMenu from 'layout/containers/menus/HeaderMenu';
import FeedHeader from 'layout/containers/menus/FeedHeader';

import LoadingScreen from 'layout/containers/LoadingScreen';
import ProtectedRoute from './utils/ProtectedRoute';
import SidebarMenu from 'layout/containers/SidebarMenu';
import { ThemeProvider } from 'emotion-theming';
import { message as antdMessage } from 'antd';
import assetgroups from 'admin/assetgroups/containers';
import assets from 'admin/assets/containers';
import assetsDetail from 'assets/containers';
import corporates from 'admin/corporates/containers';
import discover from 'discover/containers';
import errorIcon from 'layout/icons/error-icon.png';
import feedback from 'feedback/containers';
import { getAccountOrg } from 'account/modules/accountOrg';
import { getLoggedInUser } from 'account/modules/account';
import { history } from 'store';
import riskfeed from 'riskfeed/containers';
import general from 'admin/general/containers';
import lanes from 'admin/lanes/containers';
import materialgroups from 'admin/materialgroups/containers';
import materials from 'admin/materials/containers';
import playbooks from 'admin/playbooks/containers';
import productgroups from 'admin/productgroups/containers';
import products from 'admin/products/containers';
import riskmodels from 'admin/riskmodels/containers';
import riskprofiles from 'admin/riskprofiles/containers';
import { setAxiosTokenInterceptor } from './axiosInstance';
import successIcon from 'layout/icons/success-icon.png';
import theme from './theme';
import unsubscribe from 'unsubscribe/containers';
import { useAuth0 } from '@auth0/auth0-react';
import usergroups from 'admin/usergroups/containers';
import userprofile from 'modal/userprofile/containers';
import users from 'admin/users/containers';
import workflows from 'admin/workflows/containers';
import styled from '@emotion/styled';
import DiscoveredFacilityProfile from 'admin/discoverfacility/container';

const AppSidebarMenu = ({ userAuth }) => {
  const urlPathsToAvoid = ['risk-feed', 'modal'];
  const currentPath = useLocation().pathname.split('/')[1];
  return userAuth && !urlPathsToAvoid.includes(currentPath) ? <SidebarMenu /> : null;
};

const AppHeaderMenu = ({ userAuth }) => {
  const urlPathsToAvoid = ['risk-feed', 'modal'];
  const currentPath = useLocation().pathname.split('/')[1];
  return userAuth && !urlPathsToAvoid.includes(currentPath) ? <HeaderMenu /> : null;
};
const AppFooter = () => {
  const urlPathsToAvoid = ['risk-feed', 'modal'];
  const currentPath = useLocation().pathname.split('/')[1];
  const year = new Date().getFullYear();
  return !urlPathsToAvoid.includes(currentPath) ? <Footer>Everstream Analytics &copy; {year}</Footer> : null;
};

const AdminRoutes = () => {
  const isAdmin = useSelector(store => store.account.account?.isAdmin);
  const materialsEnabled = useSelector(store => store.accountOrg?.accountOrg?.prefs?.materials?.enabled);
  const actionPlanEnabled = useSelector(store => store.accountOrg?.accountOrg?.prefs?.action_plan?.enabled);
  const pathNameArray = useLocation().pathname.split('/');
  return pathNameArray[1] === 'admin' && isAdmin ? (
    <>
      <Route exact path={'/admin/'}>
        <Redirect to={'/admin/asset-groups/'} />
      </Route>
      <ProtectedRoute exact path="/admin/asset-groups/" component={assetgroups.AssetGroupView} />
      <ProtectedRoute exact path="/admin/discover-facility-profile/" component={DiscoveredFacilityProfile} />
      <ProtectedRoute exact path="/admin/risk-profiles/" component={riskprofiles.RiskProfileListView} />
      <ProtectedRoute exact path="/admin/risk-profiles/:id/" component={riskprofiles.RiskProfileDetailView} />
      <ProtectedRoute exact path="/admin/user-groups/" component={usergroups.UserGroupsView} />
      {materialsEnabled && (
        <>
          <ProtectedRoute exact path="/admin/material-groups/" component={materialgroups.MaterialGroupsView} />
          <ProtectedRoute exact path="/admin/materials/:id/" component={materials.MaterialDetailView} />
          <ProtectedRoute exact path="/admin/product-groups/" component={productgroups.ProductGroupsView} />
          <ProtectedRoute exact path="/admin/products/:id/" component={products.ProductDetailView} />
        </>
      )}
      <ProtectedRoute exact path="/admin/risk-models/" component={riskmodels.RiskModelListView} />
      <ProtectedRoute exact path="/admin/risk-models/:id/" component={riskmodels.RiskModelDetailView} />
      <ProtectedRoute exact path="/admin/assets/" component={assets.AssetListView} />
      <ProtectedRoute exact path="/admin/assets/:id/" component={assets.AssetDetailView} />
      <ProtectedRoute exact path="/admin/users/:id/" component={users.UserDetailView} />
      <ProtectedRoute exact path="/admin/lanes/" component={lanes.LaneListView} />
      <ProtectedRoute exact path="/admin/lanes/:id/" component={lanes.LaneDetailView} />
      <ProtectedRoute exact path="/admin/workflows/" component={workflows.WorkflowListView} />
      <ProtectedRoute exact path="/admin/workflows/:id/" component={workflows.WorkflowContainerView} />
      <ProtectedRoute exact path="/admin/workflows/:id/:tab/" component={workflows.WorkflowContainerView} />
      <ProtectedRoute exact path="/admin/general/" component={general.GeneralView} />
      {!!actionPlanEnabled && (
        <>
          <ProtectedRoute exact path="/admin/plan-playbooks/" component={playbooks.PlanPlaybookListView} />
          <ProtectedRoute exact path="/admin/plan-playbooks/edit" component={playbooks.PlanPlaybookDetailView} />
          <ProtectedRoute exact path="/admin/plan-playbooks/edit/:id" component={playbooks.PlanPlaybookDetailView} />
        </>
      )}
      <ProtectedRoute
        exact
        path="/admin/workflows/:id/:tab/rules/:ruleId/"
        component={workflows.WorkflowRuleDetailView}
      />
      <ProtectedRoute exact path="/admin/corporations/" component={corporates.CorporatesView} />
    </>
  ) : (
    <Route path="/admin/">
      <Redirect to="/" />
    </Route>
  );
};

const NonAdminRoutes = () => {
  const materialsEnabled = useSelector(store => store.accountOrg?.accountOrg?.prefs?.materials?.enabled);
  const discoverEnabled = useSelector(store => store.accountOrg?.accountOrg?.prefs?.discover?.enabled);
  const urlPathsToInclude = ['assets', 'discover', 'country-risk'];
  const currentPath = useLocation().pathname.split('/')[1];
  return urlPathsToInclude.includes(currentPath) ? (
    <>
      <ProtectedRoute path="/assets/:id/" component={assetsDetail.AssetDetailView} />
      <ProtectedRoute path="/assets/:id/:tabTitle" component={assetsDetail.AssetDetailView} />
      {(discoverEnabled || materialsEnabled) && (
        <ProtectedRoute path="/discover/network/" component={discover.DiscoverNetworkView} />
      )}
      <ProtectedRoute exact path="/country-risk/" component={CountryMapView} />
      <ProtectedRoute
        exact
        path={['/country-risk/:id/:tab/', '/country-risk/:id/risk/model/:riskModelId/score-details/:compId/']}
        component={CountryDetailView}
      />
    </>
  ) : null;
};

const UserProfileRoutes = () => {
  return useLocation().pathname.split('/')[1] === 'users' ? (
    <>
      <ProtectedRoute exact path="/users/:app/" component={userprofile.UserProfileView} />
      <ProtectedRoute exact path="/users/network/:id/" component={userprofile.UserProfileView} />
      <ProtectedRoute exact path="/users/network/:id/:tab/" component={userprofile.UserProfileView} />
      <ProtectedRoute
        exact
        path="/users/network/:id/:tab/rules/:ruleId/"
        component={workflows.WorkflowRuleDetailView}
      />
    </>
  ) : null;
};

const FeedRoutes = () =>
  useLocation().pathname.split('/')[1] === 'risk-feed' ? (
    <>
      <FeedHeader />
      <ProtectedRoute exact path="/risk-feed/" component={riskfeed.RiskFeedView} />
      <ProtectedRoute exact path="/risk-feed/threats/:id/plan/?channel=:channel" component={riskfeed.RiskFeedView} />
      <ProtectedRoute exact path="/risk-feed/threats/:id/:tab/" component={riskfeed.RiskFeedView} />
    </>
  ) : null;

const FullLayout = ({ userAuth }) => {
  const urlPathsToExclude = ['risk-feed'];
  const currentPath = useLocation().pathname.split('/')[1];
  return !urlPathsToExclude.includes(currentPath) ? (
    <>
      <AppSidebarMenu {...{ userAuth }} />
      <MainLayout>
        <AppHeaderMenu {...{ userAuth }} />
        <UserProfileRoutes />
        <NonAdminRoutes />
        <AdminRoutes />
        <AppFooter />
      </MainLayout>
    </>
  ) : null;
};

function App() {
  const { getAccessTokenSilently } = useAuth0();
  const [tokenConfigured, setTokenConfigured] = useState(null);
  const dispatch = useDispatch();
  const accountOrg = useSelector(store => store.accountOrg)?.accountOrg;
  const notification = useSelector(store => store.operations.notification);
  const user = useSelector(store => store.account.account);
  const redirectUrl = useSelector(store => store.account?.redirectUrl || '/users/profile/');
  const userId = user?.id;
  const orgId = accountOrg?.id;
  const userAuth = tokenConfigured && userId && orgId;

  useEffect(() => {
    async function configureToken() {
      try {
        const token = await getAccessTokenSilently();
        // Since we are using RxJS we need a way to configure axios with the
        // token before using it, so we need to wait for it first.
        await setAxiosTokenInterceptor(token);
        setTokenConfigured(true);
      } catch (error) {
        setTokenConfigured(false);
      }
    }
    configureToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (tokenConfigured && !userId) {
      dispatch(getLoggedInUser());
    }
  }, [tokenConfigured, userId, dispatch]);

  useEffect(() => {
    if (tokenConfigured && !orgId) {
      dispatch(getAccountOrg());
    }
  }, [tokenConfigured, orgId, dispatch]);

  useEffect(() => {
    if (notification?.content) {
      antdMessage[notification.type || 'info']({
        content: notification.content,
        className: `notification-${notification.type || 'info'}`,
        icon: (
          <img
            alt={'notification-icon'}
            className={'notification-icon'}
            src={notification.type === 'error' ? errorIcon : successIcon}
          />
        ),
        duration: notification?.duration ? notification.duration : 3,
      });
    }
  }, [notification]);

  if (tokenConfigured === null) {
    return <LoadingScreen />;
  }
  if (tokenConfigured && (!userId || !orgId)) {
    return <LoadingScreen />;
  }

  const PageNotFound = styled(({ className }) => {
    return <div className={className}>Page Not Found</div>;
  })`
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 26px;
    height: 60vh;
  `;

  return (
    <ThemeProvider theme={theme}>
      <MainLayout>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/feedback/:id/" component={feedback.FeedbackResponseView} />
            <Route exact path="/threats/:threat_id/untrack/:hash_id/" component={unsubscribe.UnsubscribeLandingView} />
            <Route exact path={'/'}>
              <Redirect to={redirectUrl} />
            </Route>
            <Redirect exact from={'/country-risk/:id/'} to={'/country-risk/:id/overview/'} />
            <Route>
              <FeedRoutes />
              <FullLayout {...{ userAuth }} />
            </Route>
            <Route path="*" component={PageNotFound} />
          </Switch>
        </ConnectedRouter>
      </MainLayout>
    </ThemeProvider>
  );
}

export default App;
