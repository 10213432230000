import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Skeleton, Alert, Button } from '@luxe/components';
import { Tag, Tabs, Dropdown, Menu } from 'antd';
import { DownOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Global, css } from '@emotion/react';

import { push } from 'connected-react-router';
import styled from '@emotion/styled';
import * as threatLoadingImg from 'layout/icons/threat_loading.png';

import Share from './Share';
import Remove from './Remove';

import OverviewTab from './OverviewTab';
import AssetsTab from './AssetsTab';
import LanesTab from './LanesTab';
import ShipmentsTab from './ShipmentsTab';
import MaterialsTab from './MaterialsTab';
import { unTrackThreat, trackThreat } from 'riskfeed/modules/riskfeed';
import { urlParam } from 'utils';
import useMixpanel from 'utils/hooks/Mixpanel';
import SubTiersTab from './SubTiersTab';

import { getPlan, removePlan } from 'riskfeed/modules/plans';

export const ASSET_META_OVERRIDES = {
  org_type: { description: 'Type' },
  location__city: { description: 'City' },
  location__state: { description: 'State' },
  location__postal_code: { description: 'Postal Code' },
  assets: { description: 'Assets' },
  summary__materials_out: { description: 'Materials' },
  summary__materials_final_product: { description: 'Products' },
};

const { TabPane } = Tabs;

const Loading = () => {
  return (
    <div className="message">
      <Skeleton paragraph={6} />
    </div>
  );
};

const Detail = styled(({ ...props }) => {
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();
  const { id: threatId = null } = useParams();
  const { channel = 'NETWORK_SUMMARY', track_incident, mixpanel_event } = urlParam();
  const prefs = useSelector(store => store.accountOrg?.accountOrg?.prefs);
  const discoverPref = prefs?.discover?.enabled;
  const materialPref = prefs?.materials?.enabled;
  const [expanded, setExpanded] = useState(false);
  const [shareVisible, setShareVisible] = useState(false);
  const [removeVisible, setRemoveVisible] = useState(false);
  const [trackedIncident, setTrackedIncident] = useState(false);
  const [linkedIncident, setLinkedIncident] = useState(false);
  const account = useSelector(store => store.account);
  const curr_user = account?.account;
  const isAdmin = curr_user?.isAdmin;

  const {
    threat,
    events: { items = [] },
  } = useSelector(state => state.riskfeed);
  const { pathname: locationPath } = useSelector(store => store.router.location);
  useEffect(() => {
    if (threat.item && threat.item.track_status === 'not_tracking' && track_incident === 'TRUE' && !trackedIncident) {
      dispatch(trackThreat(threat.item.id));
      mixpanelTrack('Real Time Email - Click Track');
      setTrackedIncident(true);
    }
    if (threat.item && threat.item.track_status === 'tracking' && track_incident === 'FALSE' && !trackedIncident) {
      dispatch(unTrackThreat(threat.item.id));
      mixpanelTrack('Real Time Email - Click Untrack');
      setTrackedIncident(true);
    }
  }, [dispatch, mixpanelTrack, threat.item, track_incident, trackedIncident]);

  const datasource = threat.item?.datasource;
  const familyCategoryName = threat.item?.family_category?.name;
  const familySubcategoryName = threat.item?.family_subcategory?.name;
  const industries = threat.item?.industries;

  useEffect(() => {
    const incomingLinks = [
      'assets_summary',
      'lanes_summary',
      'shipments_summary',
      'assets_table_header',
      'lanes_table_header',
      'shipments_table_header',
    ];

    const trackParams = {
      Incident: threatId,
      Category: familyCategoryName,
      Sub_category: familySubcategoryName,
      Industry: industries,
      Datasource: datasource,
    };

    if (incomingLinks.includes(mixpanel_event) && !linkedIncident) {
      let formattedAttribute = mixpanel_event
        .split('_')
        .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase());
      mixpanelTrack(`Real Time Email - Click ${formattedAttribute.join(' ')} Link`, trackParams);
      setLinkedIncident(true);
    }
  }, [
    mixpanel_event,
    mixpanelTrack,
    threatId,
    datasource,
    familyCategoryName,
    familySubcategoryName,
    industries,
    linkedIncident,
  ]);

  useEffect(() => {
    if (threat.item?.has_plan && threatId === threat.item?.id) dispatch(getPlan(threatId));
    else dispatch(removePlan());
  }, [dispatch, threatId, threat.item]);

  useEffect(() => {
    if (mixpanel_event === 'view_incident_button') {
      mixpanelTrack('Real Time Email - Click View Incident Button');
      window.history.replaceState(null, null, '?channel=NETWORK_SUMMARY');
    }
    if (mixpanel_event === 'action_plan_email_network_entity_link') {
      mixpanelTrack('Action Plan Email - Network Entity Link');
      window.history.replaceState(null, null, '?channel=MY_PLANS');
    }
  }, [mixpanel_event, mixpanelTrack]);

  if (threat.loading) {
    return (
      <div className={`${props.className} detail loading`}>
        <Loading />
      </div>
    );
  }
  if (threat.item === null) {
    const text = items.length ? (
      <>
        <h3>Select an Incident </h3>
        <p>Nothing is selected</p>
      </>
    ) : null;
    return (
      <div className={`${props.className} detail blank`}>
        <div className="message">
          <img src={threatLoadingImg} alt="" />
          {text}
        </div>
      </div>
    );
  }

  const expandDescription = e => {
    if (e.target.innerText === 'Show Less') {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  };

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const startDate = new Date(threat.item.created_date).toLocaleDateString('en-US', options);
  const endDate = new Date(threat.item.end).toLocaleDateString('en-US', options);
  let selectedTab = locationPath.split('/')[4];
  selectedTab = ['overview', 'assets', 'subTier', 'shipments', 'lanes', 'materials'].includes(selectedTab)
    ? selectedTab
    : 'overview';
  if (selectedTab === 'subTier' && !discoverPref) selectedTab = 'overview';

  const clickTab = key => {
    mixpanelTrack('Action Center - Click Tab', {
      Tab: key,
      Incident: threatId,
    });
    const defaultSortings = {
      subTier: 'asset__name',
    };
    dispatch(
      push(
        `/risk-feed/threats/${threatId}/${key}/?channel=${channel}${
          defaultSortings[key] ? '&order_by=' + defaultSortings[key] : ''
        }`,
      ),
    );
  };

  const name = threat.item.headline || threat.item.name;
  const {
    risk: { assets, discovered_assets, lanes, shipments, materials },
  } = threat.item;

  const assetsTab = <span>Facilities{threat?.item?.status === 'ACTIVE' ? <em>{assets?.count ?? 0}</em> : null}</span>;
  const subTierTab = (
    <span>
      Discovered Facilities{threat?.item?.status === 'ACTIVE' ? <em>{discovered_assets?.count ?? 0}</em> : null}
    </span>
  );
  const lanesTab = <span>Lanes{threat?.item?.status === 'ACTIVE' ? <em>{lanes?.count ?? 0}</em> : null}</span>;
  const shipmentsTab = (
    <span>Shipments{threat?.item?.status === 'ACTIVE' ? <em>{shipments?.count ?? 0}</em> : null}</span>
  );

  const materialsTab = (
    <span>Materials{threat?.item?.status === 'ACTIVE' ? <em>{materials?.count ?? 0}</em> : null}</span>
  );

  const handleMenuClick = e => {
    if (e.key === '1') {
      setRemoveVisible(true);
    } else if (e.key === '2') {
      mixpanelTrack('Action Center - Untrack', {
        Incident: threat.item.id,
      });
      dispatch(unTrackThreat(threat.item.id));
    } else if (e.key === '3') {
      mixpanelTrack('Action Center - Track', {
        Incident: threat.item.id,
      });
      dispatch(trackThreat(threat.item.id));
    } else if (e.key === '4') {
      const isHighCount = discovered_assets?.count > 200;
      window.open(
        `/discover/network/?inc_id=${threat.item.id}&ref=action_center&is_high_count=${isHighCount}`,
        '_blank',
      );
    }
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        (materialPref || discoverPref) &&
          discovered_assets?.count && {
            label: 'Show Sub-Tier Network',
            key: '4',
          },
        isAdmin &&
          !threat.item.remove_status && {
            label: 'Remove Incident',
            key: '1',
          },
        {
          label: threat.item.track_status === 'not_tracking' ? 'Track' : 'Untrack',
          key: threat.item.track_status === 'not_tracking' ? '3' : '2',
        },
      ].filter(e => e)}
    />
  );

  const hasPlan = () => {
    return threat?.item?.has_plan || threat?.item?.feedback_requested;
  };

  const showTabs = () => {
    if (threat?.item?.status === 'ACTIVE') return true;
    return hasPlan();
  };
  return (
    <div className={`${props.className} detail`}>
      <div className="detail-header">
        <header>
          <div className="incident-category">
            <Tag className="threat-tag">
              {threat.item.family_subcategory?.name || threat.item.family_category?.name}
            </Tag>
          </div>
          <h3>{name}</h3>
          <p>
            <strong>
              Impact Duration: {startDate} - {endDate}
            </strong>
          </p>
          <p className={expanded ? null : 'threat-description'}>{threat.item.description}</p>
          <div className="threat-detail" onClick={expandDescription}>
            {expanded ? 'Show Less' : 'Show More'}
          </div>
        </header>
        <nav>
          <Button variant="primary" onClick={() => setShareVisible(true)} className="risk-buttons">
            Share Incident
          </Button>
          <Global
            styles={css`
              .ant-dropdown-menu-root {
                background-color: #2e2e2e !important;
              }
              .ant-dropdown-menu-title-content {
                color: white;
              }
              .ant-dropdown-menu-item-active {
                background-color: #2e2e2e !important;
              }
            `}
          />
          <Dropdown overlay={menu} trigger={['click']}>
            <Button variant="secondary" className="risk-buttons more">
              More <DownOutlined />
            </Button>
          </Dropdown>
        </nav>
      </div>
      {threat.item.remove_status ? (
        <div className="alert">
          <Alert
            description="This incident has been removed from scoring and notifications for your organization."
            type="warning"
          />
        </div>
      ) : null}
      <Tabs
        tab="Risk Detail Tab"
        activeKey={selectedTab}
        onChange={clickTab}
        className="detail-tab"
        moreIcon={<EllipsisOutlined style={{ color: 'white' }} />}
        popupClassName="tabs-drop-down-items"
      >
        <TabPane className="overview" tab="Overview" key="overview">
          <OverviewTab />
        </TabPane>
        {showTabs() && (
          <>
            <TabPane tab={assetsTab} key="assets">
              {selectedTab === 'assets' && <AssetsTab />}
            </TabPane>
            {discoverPref ? (
              <TabPane tab={subTierTab} key="subTier">
                {selectedTab === 'subTier' && <SubTiersTab />}
              </TabPane>
            ) : null}
            <TabPane tab={lanesTab} key="lanes">
              {selectedTab === 'lanes' && <LanesTab />}
            </TabPane>
            <TabPane tab={shipmentsTab} key="shipments">
              {selectedTab === 'shipments' && <ShipmentsTab />}
            </TabPane>
            <TabPane tab={materialsTab} key="materials">
              {selectedTab === 'materials' && <MaterialsTab />}
            </TabPane>
          </>
        )}
      </Tabs>

      {shareVisible && (
        <Share visible={shareVisible} setVisible={setShareVisible} mixpanelTrack={mixpanelTrack} incidentName={name} />
      )}
      <Remove visible={removeVisible} setVisible={setRemoveVisible} mixpanelTrack={mixpanelTrack} />
    </div>
  );
})`
  .ant-dropdown-menu-title-content {
    background-color: red;
  }

  &.detail {
    padding: 24px;
    border-left: 1px solid #141414;

    h3 {
      font-weight: 400;
      font-size: 50px;
      line-height: 28px;
    }

    p {
      font-weight: 400px;
      font-size: 13px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.65);
    }

    strong {
      font-size: 14px;
      font-weight: 600px;
      color: rgba(255, 255, 255, 0.65);
    }

    nav {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: #eeeeee;
      width: 212px;
      margin-top: 35px;
    }

    .incident-category {
      display: flex;
      justify-content: space-between;
    }

    .alert {
      .ant-alert-with-description.ant-alert-no-icon {
        max-width: 660px;
        border: 2px solid #ff8700;
        border-radius: 4px;
        margin-bottom: 25px;
        background-color: #ffd6a8;
      }
      .ant-alert-with-description .ant-alert-description {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        color: #000000;
        margin-top: -10px;
      }
    }

    &.loading,
    &.blank {
      display: flex;
      .message {
        align-self: center;
        align-items: center;
        flex-grow: 1;
        text-align: center;

        img {
          width: 225px;
          height: 225px;
          margin-left: 21px;
        }

        p {
          font-size: 14px;
          font-weight: 600;
          color: #c3c3c3;
        }
      }
    }

    .detail-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    .threat-tag {
      display: flex;
      background: black;
      border-radius: 30px;
      border: 1px solid #246472;
      padding: 0px 10px;
      margin-bottom: 10px;
      color: white;
    }

    .threat-description {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .threat-detail {
      cursor: pointer;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.65);
      float: right;
    }

    .risk-dropdown {
      width: 188px;
      .ant-select-selection-search {
        background-color: green;
      }
      .ant-dropdown-menu .ant-dropdown-menu-root {
        background-color: red;
      }
    }
    &.ant-dropdown-menu .ant-dropdown-menu-vertical {
      background-color: red;
    }

    .risk-buttons {
      width: 188px;
      height: 32px;
      margin-left: 24px;
      margin-bottom: 8px;
    }

    .detail-tab {
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${props => props.theme.colors.primary} !important;
        font-weight: 700;
      }
      .ant-tabs-tab {
        @media (max-width: 1550px) {
          font-size: 13px;
          margin-left: 15px;
        }
      }
      .ant-tabs-ink-bar {
        border-bottom: 2px solid #2d8fa5;
      }
      .ant-tabs-tab:nth-of-type(1) {
        margin-left: 20px;
      }

      .ant-tabs-tab.ant-tabs-tab-active {
        span {
          font-weight: 700;
          em {
            font-weight: 400;
          }
        }
      }

      .ant-tabs-tab-btn {
        color: white;
        em {
          color: white;
          background: #545454;
          border-radius: 6px;
          padding: 1px 8px;
          font-size: 10px;
          margin-left: 8px;
          font-style: normal;
          vertical-align: middle;
        }
      }
    }

    &.loading {
      .message {
        align-self: flex-start;
        padding: 52px 25px;
      }
    }

    h3 {
      font-size: 20px;
    }

    dl {
      margin: 24px 0;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      flex-flow: column wrap;
      max-height: 6em;

      dt {
        color: #b1b1b1;
        margin-bottom: 4px;
      }

      dd {
        color: #dedede;
        font-weight: 700;

        margin: 0;
        padding: 0px 0px 0px 8px;
        min-height: 3em;
      }
    }
  }
`;
export default Detail;
