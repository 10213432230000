import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Col, Row, Form } from 'antd';
import { Numeric as InputNumber } from '@luxe/components';
import { Select } from 'layout/Select';
import { RULE_TYPES } from 'admin/constants';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import * as converters from 'admin/riskprofiles/modules/utils';

import AttributeSelect from './AttributeSelect';

const message = 'Restrict Risk Score to defined range';

const METRIC = 'metric';
const IMPERIAL = 'imperial';

const OptionLabel = styled.span(props => ({
  marginRight: props.right ? props.theme.space.small : props.theme.space.none,
  marginLeft: props.left ? props.theme.space.small : props.theme.space.none,
}));

const modelEndpointMap = {
  supplier_location: 'supplier-location',
  shipment: 'shipment',
};

const TimeSeriesAbsoluteOption = ({ options, onOptionChange, disabled }) => {
  const onCheck = event => {
    const newOptions = { ...options, limit_to_values: event.target.checked };
    onOptionChange(newOptions);
  };
  return (
    <Checkbox
      defaultChecked={options.limit_to_values}
      style={{ color: 'white' }}
      disabled={disabled}
      onChange={onCheck}
    >
      {message}
    </Checkbox>
  );
};

const NumberOptions = props => {
  const { text, value, onChange, disabled } = props;
  const min = props.min || 0.01;
  const style = props.style || {
    marginBottom: '5px',
    height: '38px',
    width: '80px',
    borderRadius: '4px',
    paddingTop: '3px',
  };
  return (
    <>
      {text && <OptionLabel right>{text}</OptionLabel>}
      <InputNumber
        min={min}
        value={value?.toFixed(1)}
        precision={1}
        onChange={onChange}
        style={style}
        disabled={disabled}
      />
    </>
  );
};

const AttributeOption = ({ options, onOptionChange: onOptionsValuesChange, disabled }) => {
  const onChangeAttribute = selectedOption => {
    const newSelectedOption = {
      ...options,
      attribute: selectedOption.value,
      display_name: selectedOption.label,
    };

    onOptionsValuesChange(newSelectedOption);
  };

  const onChangeLimit = event => {
    const newOptions = { ...options, limit_to_values: event.target.checked };
    onOptionsValuesChange(newOptions);
  };

  const initLimit = options.limit_to_values === undefined ? false : options.limit_to_values;
  const model = modelEndpointMap[options.model];

  return (
    <Form layout="vertical">
      <Form.Item required label={<label style={{ color: 'white' }}>Attribute</label>}>
        <AttributeSelect value={options.attribute} onChange={onChangeAttribute} modelType={model} disabled={disabled} />
      </Form.Item>
      <Form.Item>
        <Checkbox defaultChecked={initLimit} onChange={onChangeLimit} style={{ color: 'white' }} disabled={disabled}>
          {message}
        </Checkbox>
      </Form.Item>
    </Form>
  );
};

const DistanceOption = ({ options, onOptionChange: onOptionsValuesChange, disabled }) => {
  // database stores this value in meters as a string
  // this function defaults to metric values
  const defaultUnit = useSelector(store => store.account.account?.prefs.units.default);
  let distance = options.meters ? options.meters / 1000 : 10;
  let distanceDescription = 'km';

  if (defaultUnit === 'imperial') {
    distance = options.meters ? options.meters * 0.00062137119 : 10;
    distanceDescription = 'mi';
  }

  const updateDistance = value => {
    let savedDistance = value * 1000;
    if (defaultUnit === 'imperial') {
      savedDistance = Math.round(value * 1609.344);
    }
    const newOptions = { ...options, meters: parseInt(savedDistance) };
    onOptionsValuesChange(newOptions);
  };

  const label = 'Within Distance (' + distanceDescription + ')';
  return <NumberOptions text={label} value={distance} onChange={updateDistance} disabled={disabled} />;
};

const DurationOption = ({ options: initialOptions, onOptionChange: onOptionsValuesChange, disabled }) => {
  const defaultUnit = useSelector(store => store.account.account?.prefs.units.default);
  const displayValue =
    defaultUnit === METRIC
      ? parseInt(converters.convertFarenheitToCelsius(initialOptions.value))
      : initialOptions.value;

  const tempUnitofMeasure = defaultUnit === IMPERIAL ? 'F' : 'C';

  const options = [
    { value: 'LT', label: '<' },
    { value: 'LTE', label: '<=' },
    { value: 'EQ', label: '=' },
    { value: 'GT', label: '>' },
    { value: 'GTE', label: '>=' },
  ];

  const initOperator = options.filter(opt => {
    return opt.value === initialOptions.operator;
  });

  const inputNumberHandler = inputValue => {
    const convertedValue =
      defaultUnit === METRIC ? parseInt(converters.convertCelsiusToFarenheit(inputValue)) : inputValue;
    const newOptions = { ...initialOptions, value: convertedValue };
    onOptionsValuesChange(newOptions);
    initialOptions = { ...newOptions };
  };
  const selectHandler = value => {
    const newOptions = { ...initialOptions, operator: value.value };
    onOptionsValuesChange(newOptions);
    initialOptions = { ...newOptions };
  };

  const label = 'Degrees (' + tempUnitofMeasure + ')';
  return (
    <Form>
      <Row gutter={10} align="middle">
        <Col style={{ height: '60px', color: 'white' }}>
          <div style={{ marginTop: '7px' }}>{label}</div>
        </Col>
        <Col span={7} style={{ height: '60px', color: 'white' }}>
          <Select defaultValue={initOperator} onChange={selectHandler} options={options} isDisabled={disabled} />
        </Col>
        <Col style={{ height: '60px', color: 'white' }}>
          <NumberOptions min={-100} value={displayValue} onChange={inputNumberHandler} disabled={disabled} />
        </Col>
      </Row>
    </Form>
  );
};

const TimeSeriesWindow = ({ options: initialOptions, onOptionChange: onOptionsValuesChange, disabled }) => {
  const onChange = value => {
    const newOptions = { ...initialOptions, periods: value };
    onOptionsValuesChange(newOptions);
    initialOptions = { ...newOptions };
  };
  const onSelect = ({ value }) => {
    const newOptions = { ...initialOptions, func: value };
    onOptionsValuesChange(newOptions);
    initialOptions = { ...newOptions };
  };
  const onCheck = event => {
    const newOptions = { ...initialOptions, limit_to_values: event.target.checked };
    onOptionsValuesChange(newOptions);
    initialOptions = { ...newOptions };
  };

  const options = [
    { value: 'MIN', label: 'MIN' },
    { value: 'MAX', label: 'MAX' },
    { value: 'AVG', label: 'AVG' },
    { value: 'SUM', label: 'SUM' },
  ];

  const initFunc = options.filter(opt => {
    return opt.value === initialOptions.func;
  });
  return (
    <Form>
      <Row gutter={10} align="middle">
        <Col span={8} style={{ fontSize: 12, color: 'white', height: '80px' }}>
          <OptionLabel right>{'Function'}</OptionLabel>
          <Select defaultValue={initFunc} onChange={onSelect} options={options} isDisabled={disabled} />
        </Col>
        <Col span={16} style={{ fontSize: 12, color: 'white', height: '80px' }}>
          <NumberOptions text="Hours" value={initialOptions.periods} onChange={onChange} disabled={disabled} />
        </Col>
      </Row>
      <Row gutter={[40, 20]}>
        <Col>
          <Checkbox
            defaultChecked={initialOptions.limit_to_values}
            onChange={onCheck}
            style={{ color: 'white' }}
            disabled={disabled}
          >
            {message}
          </Checkbox>
        </Col>
      </Row>
    </Form>
  );
};

const OPTIONS_TYPES = {
  [RULE_TYPES.TIME_SERIES_ABSOLUTE]: TimeSeriesAbsoluteOption,
  [RULE_TYPES.SPATIAL_INTERSECT]: DistanceOption,
  [RULE_TYPES.DISTANCE_BASED]: DistanceOption,
  [RULE_TYPES.DURATION_BASED]: DurationOption,
  [RULE_TYPES.TIME_SERIES_WINDOW]: TimeSeriesWindow,
  [RULE_TYPES.CUSTOM_ATTRIBUTE]: AttributeOption,
};

const Options = ({ rule, onChange, disabled = false }) => {
  const { data } = rule;
  const { type } = rule.catalog;
  const { options } = data;

  const onOptionChange = newOptions => {
    let newData = { data: { ...data, ...{ options: newOptions } } };
    onChange(newData);
  };

  const selectOptionWidget = () => {
    const widget = OPTIONS_TYPES[type];
    return widget({ options, onOptionChange, disabled });
  };

  const widget = selectOptionWidget();
  return <>{widget}</>;
};

Options.propTypes = {
  rule: PropTypes.object.isRequired,
};
export default Options;
