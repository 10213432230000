import { DownOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import { Avatar, Dropdown, Menu, Space } from 'antd';
import getFromConfig from 'constants/config';
import { useTheme } from 'emotion-theming';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const MenuItem = styled(Menu.Item)`
  background-color: ${props => props.theme.colors.black1};

  font-size: 12px;
  &:hover {
    background-color: ${props => props.theme.colors.black1};
  }

  &.link {
    color: ${props => props.theme.colors.white};
  }

  &.link:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.primary};
  }

  &.info {
    cursor: auto;
    background-color: ${props => props.theme.colors.black2};
  }

  a {
    color: ${props => props.theme.colors.white} !important;
  }
`;

const AccountInfo = styled.div`
  background-color: ${props => props.theme.colors.black2};
  padding: 5px 0;

  p {
    font-size: 10px;
    line-height: 1.3em;
    color: white;
    padding: 0;
    margin: 0;
    &:hover {
      background-color: ${props => props.theme.colors.primary} !important;
    }
    a {
      padding: 10px;
      color: white;
      &:visited,
      &:focus {
        color: white !important;
      }
    }
  }
`;

const UserMenu = ({ accountOrg, account }) => {
  const { logout, user, isLoading } = useAuth0();
  const org = accountOrg?.accountOrg;
  const loggedInUser = account?.account;
  const history = useHistory();
  const location = useLocation();
  const isSamePath = location.pathname?.includes('/discover/network/');
  const { colors } = useTheme();
  const isAdmin = loggedInUser?.isAdmin;

  if (isLoading || !org || !loggedInUser) {
    return null;
  }

  const nameString = user.name || loggedInUser.name || '';
  const nameSplits = nameString ? nameString.trim().split(' ') : [];

  let abbv = '';
  if (nameSplits.length > 0) {
    abbv += nameSplits[0][0].toUpperCase();
  }
  if (nameSplits.length > 1) {
    abbv += nameSplits.at(-1)[0].toUpperCase();
  }
  const menu = (
    <Menu
      style={{ backgroundColor: colors.black1, borderWidth: '1px', borderColor: colors.black2, borderStyle: 'solid' }}
    >
      <MenuItem key="0" className="info">
        <AccountInfo>
          <p>{user.email || loggedInUser.email}</p>
          <p>{org.name}</p>
        </AccountInfo>
      </MenuItem>
      <MenuItem key="1" className="link">
        <a href={getFromConfig('REACT_APP_RISKBOX_BASE_URL')}>Everstream Map</a>
      </MenuItem>
      {(org?.prefs?.discover?.enabled || org?.prefs?.materials?.enabled) && !isSamePath && (
        <MenuItem key="2" className="link">
          <a href="/discover/network/">Network</a>
        </MenuItem>
      )}
      <MenuItem key="3" className="link">
        <a href="/users/profile/">Profile</a>
      </MenuItem>
      {isAdmin && (
        <MenuItem key="4" className="link" onClick={() => history.push('/admin/')}>
          Account Admin
        </MenuItem>
      )}
      <Menu.Divider style={{ backgroundColor: colors.black2 }} />
      <MenuItem
        key="5"
        className="link"
        onClick={() => logout({ returnTo: `${getFromConfig('REACT_APP_RISKBOX_BASE_URL')}logout-all/` })}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
      <a href="/" onClick={e => e.preventDefault()}>
        <Space size="small" style={{ color: 'white', fontSize: '12px' }}>
          <Avatar style={{ backgroundColor: colors.primary }}>{abbv}</Avatar>
          {nameString}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};

function mapStateToProps({ accountOrg, account }) {
  return {
    accountOrg,
    account,
  };
}

export default connect(mapStateToProps)(UserMenu);
