import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import UserMenu from 'layout/containers/menus/UserMenu';
import MapIcon from '../../icons/map.svg';
import SupplyChainIcon from '../../icons/supply-chain-icon.svg';
import EarthIcon from '../../icons/earth.svg';
import getFromConfig from 'constants/config';

const FeedHeader = styled(props => {
  const { accountOrg } = useSelector(store => store.accountOrg);
  const isAdmin = useSelector(store => store.account.account?.isAdmin);
  return (
    <div className={`${props.className}`}>
      <div className="menu-items">
        {getFromConfig('REACT_APP_COMET_URL') && (accountOrg?.prefs?.ux_overhaul || isAdmin) && (
          <div className="menu-icon text-center">
            <a rel="noopener noreferrer" href={`${getFromConfig('REACT_APP_COMET_URL')}incidents/`} target="_blank">
              <img src={SupplyChainIcon} alt="Supply Chain Icon" />
              <div>Supply Chain</div>
            </a>
          </div>
        )}
        <div className={'menu-icon'}>
          <a href={getFromConfig('REACT_APP_RISKBOX_BASE_URL')}>
            <img src={MapIcon} alt="map-icon" />
            <div>Map</div>
          </a>
        </div>
        {(accountOrg?.prefs?.discover?.enabled || accountOrg?.prefs?.materials?.enabled) && (
          <div className={'menu-icon'}>
            <a href="/discover/network/">
              <img src={EarthIcon} alt="earth-icon" className="network-icon" />
              <div className="description">Network</div>
            </a>
          </div>
        )}
      </div>
      <UserMenu />
    </div>
  );
})`
  background-color: #454647;
  display: flex;
  flex-direction: row;
  height: 66px !important;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 24px;
  width: 100%;
  .menu-items {
    display: flex;
    left: 40px;
    position: absolute;
    .menu-icon {
      margin: 0 10px;
    }
  }
  a {
    color: white;
  }
  img {
    margin-left: 5px;
  }
  .network-icon {
    margin-left: 15px;
  }
  .text-center {
    text-align: center;
  }
`;
export default FeedHeader;
